/* eslint-disable camelcase */
import React, { CSSProperties } from 'react';
import { Box, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons/lib';

import ResponsiveIdDisplay from '../responsiveIdDisplay';
import Badge from '../../../badge';
import Autocomplete from '../../autocomplete';
import ProgressBar from '../progressBar';
import IconButton from '../../../icons/iconButton';
import { getStatusDetails } from '../../../../utils/handleStatus';
import { checkFullDateList } from '../../../../utils/checkValues';
import copyText from '../../../../utils/copyText';
import { TimeDisplayWithSecond } from '../../../../utils/formatDate';
import handleDisplayDelayedTime from '../../../../utils/delayedTime';
import { handleIcon } from '../../../../utils/tags';
import {
  actionColors,
  actionTranslations,
} from '../../../../sections/flow/form/utils';
import { handleSLA, handleSLAIcon, handleScore, handleShowSLA } from './utils';

import { theme } from '../../../../styles/theme';
import * as I from '../../../../interfaces/order';
import * as I2 from './interfaces';

export const descriptionAndIDColumn = (pathname: string) => {
  return {
    title: 'Nome',
    field: 'description',
    cellStyle: { minWidth: 220 },
    render: (rowData: { id: string; description: string }): React.ReactNode => {
      return (
        <Tooltip
          title="Visualizar"
          placement="bottom"
          style={{ width: 'fit-content' }}
        >
          <NavLink
            to={{
              pathname: `${pathname}/${rowData.id}`,
            }}
          >
            {rowData.description}{' '}
          </NavLink>
        </Tooltip>
      );
    },
  };
};

export const nameAndIDColumn = (pathname: string) => {
  return {
    title: 'Nome',
    field: 'name',
    cellStyle: { minWidth: 220 },
    render: (rowData: { id: string; name: string }): React.ReactNode => {
      const normalizedPath = pathname.replace(/\/$/, '');
      const linkTo =
        normalizedPath === '/partners/info'
          ? `${normalizedPath}/${rowData.id}`
          : { pathname, search: `?id=${rowData.id}` };

      return (
        <Tooltip
          title="Visualizar"
          placement="bottom"
          style={{ width: 'fit-content' }}
        >
          <NavLink to={linkTo}>{rowData.name}</NavLink>
        </Tooltip>
      );
    },
    editComponent: (editProps: {
      onChange: (e: object) => void;
    }): React.ReactElement => (
      <div className="default--input">
        <Autocomplete onChange={(e: object): void => editProps.onChange(e)} />
      </div>
    ),
  };
};

export const listNameAndIDColumn = (pathname: string) => {
  return {
    title: 'Nome',
    field: 'list.name',
    cellStyle: { minWidth: 220 },
    render: (rowData: {
      list: { name: string; id: string };
    }): React.ReactNode => {
      return (
        <Tooltip
          title="Visualizar"
          placement="bottom"
          style={{ width: 'fit-content' }}
        >
          <NavLink
            to={{
              pathname,
              search: `id=${rowData.list.id}`,
            }}
          >
            {rowData.list.name}
          </NavLink>
        </Tooltip>
      );
    },
    editComponent: (editProps: {
      onChange: (e: object) => void;
    }): React.ReactElement => (
      <div className="default--input">
        <Autocomplete onChange={(e: object): void => editProps.onChange(e)} />
      </div>
    ),
  };
};

export const IdColumn = {
  title: 'id',
  field: 'id',
  editable: 'never' as const,
  sorting: false,
  cellStyle: { minWidth: 220 },
  render: (rowData: { id: string }): React.ReactNode => {
    const handleCopy = (): void => copyText(rowData.id);

    return (
      <Stack direction="row" spacing="small" alignItems="center">
        <ResponsiveIdDisplay id={rowData.id} />
        <Box minW="1.125rem">
          <IconButton fontSize="larger" onClick={handleCopy} />
        </Box>
      </Stack>
    );
  },
};

export const ExternalIdColumn = {
  title: 'external id',
  field: 'external_id',
  emptyValue: '',
  editable: 'never' as const,
  sorting: false,
  cellStyle: { minWidth: 220 },
  render: (rowData: { external_id: string }): React.ReactNode => {
    const handleCopy = (): void => copyText(rowData.external_id);

    return (
      <Stack direction="row" spacing="small">
        <ResponsiveIdDisplay id={rowData.external_id} />
        <Box minW="1.125rem">
          <IconButton fontSize="larger" onClick={handleCopy} />
        </Box>
      </Stack>
    );
  },
};

export const createdAtColumn = {
  title: 'data da criação',
  field: 'created_at',
  render: (rowData: { created_at: string }): React.ReactNode => {
    return <>{checkFullDateList(rowData?.created_at)}</>;
  },
};

export const modifiedAtColumn = {
  title: 'última modificação',
  field: 'modified_at',
  render: (rowData: { modified_at: string }): React.ReactNode => {
    return <>{checkFullDateList(rowData?.modified_at)}</>;
  },
};

export const descriptionColumn = {
  title: 'Descrição',
  field: 'description',
  editable: 'never' as const,
};

export const documentColumn = {
  title: 'documento',
  field: 'document',
  editable: 'never' as const,
};

export const TagColumn = (scope: string) => {
  return {
    title: 'Tag',
    field: 'value',
    editable: 'never' as const,
    sorting: false,
    cellStyle: { minWidth: 220 },
    render: (rowData: { value: string }): React.ReactNode => {
      return (
        <Badge
          label={rowData.value}
          schema="gray"
          leftIcon={handleIcon(scope) as IconType}
          transparent
        />
      );
    },
  };
};

export const statusColumn = {
  title: 'Status',
  field: 'status',
  editable: 'never' as const,
  lookup: { enabled: 'Ativo', disabled: 'Inativo' },
  width: 100,
  render: (rowData: { status: string }): React.ReactNode => {
    return (
      <>
        {rowData.status === 'enabled' ? (
          <Badge label="Ativo" schema="green" transparent />
        ) : (
          <Badge label="Inativo" schema="red" transparent />
        )}
      </>
    );
  },
};

export const statusActiveColumn = {
  title: 'Status',
  field: 'status',
  editable: 'never' as const,
  lookup: { active: 'Ativo', inactive: 'Inativo' },
  render: (rowData: { status: string }): React.ReactNode => {
    return (
      <>
        {rowData.status === 'active' ? (
          <Badge label="Ativo" schema="green" transparent />
        ) : (
          <Badge label="Inativo" schema="red" transparent />
        )}
      </>
    );
  },
};

export const isRunnableColumn = {
  title: 'Regra vinculada',
  field: 'is_runnable',
  editable: 'never' as const,
  sorting: false,
  render: (rowData: { is_runnable: boolean }): React.ReactNode => {
    return (
      <>
        {rowData.is_runnable ? (
          <Badge label="Sim" schema="green" transparent />
        ) : (
          <Badge label="Não" schema="red" transparent />
        )}
      </>
    );
  },
};

export const AnalystsColumn = {
  title: 'Analistas',
  field: 'users',
  width: 150,
  sorting: false,
  render: (rowData: { users: string[] }): React.ReactNode => {
    return (
      <p>
        {rowData.users.slice(0, 2).join(', ')}{' '}
        {rowData.users.length > 2 && `... +${rowData.users.length - 2}`}
      </p>
    );
  },
};

export const actionsListTypeColumn = {
  title: 'Tipo',
  field: 'type',
  width: 70,
  cellStyle: { fontWeight: 'bold' },
  lookup: {
    document: 'documento',
    email: 'e-mail',
    phone: 'telefone/celular',
    'postal-code': 'código postal',
    'seller-id': 'seller-id',
    'seller-document': 'seller-document',
    'seller-name': 'seller-name',
    'client-id': 'client-id',
    bin: 'bin',
  },
};

export const targetColumn = { title: 'Valor', field: 'target' };

export const expiresAtColumn = {
  title: 'Data Limite',
  field: 'expires_at',
  render: (rowData: { expires_at: string }): React.ReactNode => {
    return <>{checkFullDateList(rowData?.expires_at)}</>;
  },
};

export const actionColumn = {
  title: 'Ação ',
  field: 'action',
  lookup: {
    approve: 'Aprovar',
    reject: 'Reprovar',
    review: 'Revisar',
  },
  cellStyle: { padding: '5px 20px' },
  render: (rowData: {
    action: 'approve' | 'reject' | 'review';
  }): React.ReactNode => {
    return (
      <Badge
        label={actionTranslations[rowData.action]}
        schema={actionColors[rowData.action]}
      />
    );
  },
};

export const orderColumnName = (form: string) => {
  return {
    title: 'Loja',
    field: 'client_id',
    sorting: false,
    filtering: false,
    cellStyle: { minWidth: 220 },
    render: (rowData: {
      id: string;
      client_name: string;
      client_id?: string;
    }): React.ReactNode => {
      return (
        <Tooltip label="Visualizar pedido" placement="bottom">
          <NavLink
            to={{
              pathname: `/orders/info/${rowData.id}`,
              state: {
                from: form,
              },
            }}
          >
            {rowData.client_name}{' '}
            {rowData.client_id ? (
              <span style={{ color: '#F05D5E' }}>
                (ID: {rowData.client_id.substring(0, 6)}...)
              </span>
            ) : (
              'Não informado'
            )}
          </NavLink>
        </Tooltip>
      );
    },
  };
};

export const orderColumnScore = {
  title: 'Score',
  field: 'score',
  emptyValue: 'Não informado',
  sorting: false,
  filtering: false,
  render: (rowData: I.OrderModel): React.ReactNode => {
    return (
      <p style={handleScore(rowData.score)}>
        {(rowData.score * 100).toFixed(1)}%
      </p>
    );
  },
};

export const retentionColumn = {
  title: 'Retenção',
  field: 'recommendation',
  cellStyle: { minWidth: 120, width: 120 },
  sorting: false,
  filtering: false,
  emptyValue: 'Não informado',
  render: (rowData: I.OrderModel): React.ReactNode => {
    const { review_detail } = rowData;
    if (!review_detail || !review_detail?.events) return null;
    const finalizeEvent = review_detail.events.find(
      (event) => event.action === 'finalize'
    );

    if (!finalizeEvent) return null;

    const { background, color } = getStatusDetails({
      status: finalizeEvent.recommendation,
      recommendation: finalizeEvent.recommendation,
    });

    return (
      <Badge
        label={
          finalizeEvent?.recommendation === 'approve' ? 'Alçada' : 'Qualidade'
        }
        background={background}
        color={color}
        fontSize="medium"
      />
    );
  },
};

export const orderColumnPaymentsStatusChargeback = {
  title: 'Status de Pagamento',
  field: 'status',
  cellStyle: { minWidth: 120, width: 120 },
  sorting: false,
  filtering: false,
  emptyValue: 'Não informado',
  render: (rowData: I.OrderModel): React.ReactNode => {
    const { payments } = rowData.order;

    if (!payments) return null;

    const status =
      payments.length >= 1 &&
      payments.filter((payment) => payment.status === 'chargeback').length >= 1
        ? 'chargeback'
        : payments[0].status;

    const { label, background, color } = getStatusDetails({
      status,
      isPaymentStatus: true,
    });

    return (
      <Badge
        label={label}
        background={background}
        color={color}
        fontSize="small"
      />
    );
  },
};

export const orderColumnDocument = {
  title: 'Documento',
  field: 'order.buyer.document',
  sorting: false,
  filtering: false,
};

export const orderColumnEmail = {
  title: 'Email',
  field: 'order.buyer.email',
  sorting: false,
  filtering: false,
};

export const orderColumnPaymentsAmount = {
  title: 'Valor',
  field: 'order.payments_sum_amount@display',
  cellStyle: { minWidth: 120, width: 120 },
  sorting: false,
  filtering: false,
  emptyValue: 'Não informado',
};

export const orderColumnQueueTime = {
  title: 'Em revisão',
  field: 'review_detail.queue_time',
  emptyValue: '--:--',
  sorting: false,
  filtering: false,
  type: 'time' as const,
  render: (rowData: I.OrderModel): React.ReactNode => {
    return (
      <>
        {handleShowSLA(rowData?.recommendation) ? (
          <p>{TimeDisplayWithSecond(rowData?.review_detail?.queue_time)}</p>
        ) : (
          <p>--:--</p>
        )}
      </>
    );
  },
};

export const orderColumnSecondsUntilSLA = {
  title: 'Tempo até estourar a SLA',
  field: 'review_detail.seconds_until_sla',
  emptyValue: '--:--',
  sorting: false,
  filtering: false,
  type: 'time' as const,
  render: (rowData: I.OrderModel): React.ReactNode => {
    return (
      <>
        {handleShowSLA(rowData?.recommendation) ? (
          <p style={handleSLA(rowData?.review_detail?.seconds_until_sla)}>
            {handleSLAIcon(rowData?.review_detail?.seconds_until_sla)}
            {rowData?.review_detail?.seconds_until_sla < 0 && ' -'}
            {TimeDisplayWithSecond(rowData?.review_detail?.seconds_until_sla)}
          </p>
        ) : (
          <p>--:--</p>
        )}
      </>
    );
  },
};

export const orderColumnSLALimit = {
  title: 'limite do sla',
  field: 'review_detail.seconds_until_sla',
  emptyValue: '--:--',
  sorting: false,
  filtering: false,
  type: 'time' as const,
  render: (rowData: I.OrderModel): React.ReactNode => {
    return (
      <ProgressBar initialTime={rowData.review_detail.seconds_until_sla} />
    );
  },
};

export const orderColumnCreatedAt = (sort: 'asc' | 'desc') => {
  return {
    title: 'Data e Hora de Entrada',
    field: 'created_at',
    type: 'datetime' as const,
    defaultSort: sort,
    filtering: false,
    dateSetting: { format: 'DD/MM/YYYY HH:mm' },
  };
};

export const orderColumnDate = {
  title: 'Data e Hora do Pedido',
  field: 'order.date',
  type: 'datetime' as const,
  filtering: false,
  sorting: false,
  dateSetting: { format: 'DD/MM/YYYY HH:mm' },
};

export const orderColumnStatus = ({
  isSuspension = false,
}: I2.OrderColumnStatusType) => {
  return {
    title: 'Status',
    field: 'status',
    sorting: false,
    filtering: true,
    lookup: {
      pending: 'Pendente',
      'not-analyzed': 'Não Analisado',
      reject: 'Reprovado',
      approve: 'Aprovado',
    },
    defaultFilter: ['pending'],
    cellStyle: {
      padding: '5px 20px',
      textAlign: 'center',
      textTransform: 'capitalize',
    } as CSSProperties,
    render: (rowData: I.OrderModel): React.ReactNode => {
      const status = getStatusDetails({
        status: rowData?.status ?? 'default-status',
        recommendation: rowData?.recommendation ?? 'default-recommendation',
        reviewDetail: rowData?.review_detail?.state ?? 'default-review',
        suspensionDetail: isSuspension
          ? rowData.suspension_detail?.state
          : undefined,
      });

      return (
        <Tooltip
          label={handleDisplayDelayedTime(rowData?.review_detail)}
          placement="bottom"
        >
          <Stack
            direction="row"
            spacing="smallest"
            alignItems="center"
            justifyContent="center"
            padding="0.25rem 0.5rem"
            fontWeight="600"
            borderRadius={theme.radii.huge}
            style={{ background: status.background, color: status.color }}
          >
            <Text>{status.label}</Text>

            <Box w="1rem" h="1rem">
              <Icon style={{ display: 'block' }} as={status.icon} />
            </Box>
          </Stack>
        </Tooltip>
      );
    },
  };
};

export const orderColumnReviewer = {
  title: 'Revisor',
  field: 'review_detail.reviewer',
  sorting: false,
  filtering: false,
};
