/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AbsoluteCenter, Icon, Stack } from '@chakra-ui/react';
import { FiLink2 } from 'react-icons/fi';
import { Query, QueryResult } from '@material-table/core';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from '../../styles/main';
import { ClientModel } from '../../interfaces/client';
import useClients from '../../hooks/api/clients';
import Breadcrumb from '../../components/base/v2/breadcrumb';
import Title from '../../components/base/v2/title';
import Button from '../../components/base/v2/button';
import DefaultTable, { fetchDataTable } from '../../components/base/v2/table';
import {
  ExternalIdColumn,
  IdColumn,
  documentColumn,
  isRunnableColumn,
  nameAndIDColumn,
} from '../../components/base/v2/table/columns';
import usePartners from '../../hooks/api/partners';
import { PartnersModel } from '../../interfaces/partners';
import LoadingSpinner from '../../components/loadingSpinner';

const PartnersClientsInfo: React.FC = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [partner, setPartner] = useState<PartnersModel>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { getAllClients } = useClients();
  const { getOnePartner, postBondPartnersClients, deleteBondPartnersClients } =
    usePartners();

  const tableRef = useRef<any>();

  const { id } = useParams<{ id: string }>();

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  const reloadTable = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const loadData = (
    query: Query<ClientModel>
  ): Promise<QueryResult<ClientModel>> => {
    const data = {
      partner: {
        id,
      } as PartnersModel,
    };

    return fetchDataTable(
      query,
      (fetchQuery) => getAllClients({ data, query: fetchQuery }),
      (total) => {
        setIsLoaded(true);
        setTotalItems(total);
      },
      () =>
        toast.error(
          'Ocorreu um erro inesperado ao buscar os clientes. Tente novamente mais tarde.'
        )
    );
  };

  const clientTableEdit = {
    onRowAdd: (data: { name: ClientModel }): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          const client = data.name;

          if (client?.id === undefined) {
            toast.error(
              'Erro ao vincular o cliente ao fluxo. É necessário selecionar um cliente para realizar o vínculo.'
            );
            resolve(reloadTable());
            return;
          }

          postBondPartnersClients({
            id: client.id,
            scopeID: id ?? '',
            version: client.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success(
                  'O vínculo do cliente ao parceiro foi realizado com sucesso!'
                );
                reloadTable();
                resolve();
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.error(
                `Não foi possível realizar o vínculo entre o cliente e o parceiro. ${reason}`
              );
              resolve(reloadTable());
            })
            .catch(() => {
              toast.error(
                `Não foi possível realizar o vínculo entre o cliente e o parceiro. Tente novamente mais tarde.`
              );
              resolve(reloadTable());
            });
        }, 1000);
      }),

    onRowDelete: (client: ClientModel): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          deleteBondPartnersClients({
            id: client.id,
            scopeID: id ?? '',
            version: client.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success('Vínculo com o cliente removido com sucesso!');
                setTimeout(() => {
                  reloadTable();
                  setTotalItems((prevTotal) => prevTotal - 1);
                  resolve();
                }, 1000);
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.error(
                `Não foi possível remover o vínculo do cliente. ${reason}`
              );
              resolve(reloadTable());
            })
            .catch(() => {
              toast.error(
                'Não foi possível remover o vínculo, tente novamente mais tarde.'
              );
              resolve(reloadTable());
            });
        }, 1000);
      }),
  };

  useEffect(() => {
    setIsLoaded(false);
    let cleanUp = true;

    const handleGetPartner = async (): Promise<void> => {
      try {
        const response = await getOnePartner({ id });
        if (response?.data) {
          setPartner(response.data);
          setIsLoaded(true);
        }
      } catch (error) {
        setIsLoaded(true);
        toast.error(
          'Ocorreu um erro inesperado ao buscar o parceiro. Tente novamente mais tarde.'
        );
      }
    };

    if (cleanUp) {
      handleGetPartner();
    }

    return () => {
      cleanUp = false;
    };
  }, [id]);

  if (!isLoaded) {
    return (
      <S.Main>
        <AbsoluteCenter>
          <LoadingSpinner />
        </AbsoluteCenter>
      </S.Main>
    );
  }

  return (
    <S.Main>
      <Stack spacing="medium" pt=".5rem" pb="2.5rem">
        <Breadcrumb
          items={[
            { label: 'Início', href: '/' },
            { label: 'Parceiros', href: '/partners' },
            {
              label: partner?.name ?? 'carregando...',
              href: `/partners/info/${id}`,
            },
            { label: 'Clientes' },
          ]}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title showBackButton text="Clientes" quantity={totalItems} />
          <Button
            onClick={onButtonClick}
            colorScheme="v2.brand"
            leftIcon={<Icon as={FiLink2} />}
            p="13px 20px"
            fontSize="medium"
            borderRadius="large"
          >
            Vincular novo cliente
          </Button>
        </Stack>
      </Stack>

      <DefaultTable
        columns={[
          nameAndIDColumn('/client/info'),
          IdColumn,
          documentColumn,
          ExternalIdColumn,
          isRunnableColumn,
        ]}
        data={loadData}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEdit}
        tableRef={tableRef}
      />
    </S.Main>
  );
};

export default PartnersClientsInfo;
