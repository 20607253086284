/* eslint-disable react-hooks/rules-of-hooks */
import { atomWithReset, useResetAtom } from 'jotai/utils';
import queryString from 'query-string';
import { removeEmptyValues } from '../../../../utils/removeEmptyValues';
import { handleFormToFiltersModel } from './utils';

import * as I from './interfaces';

const initialquery = removeEmptyValues(
  queryString.parse(window.location.search)
);

export const filterByAtom = atomWithReset(initialquery.filterBy);
export const searchTextAtom = atomWithReset(initialquery.text);
export const filtersAtom = atomWithReset<I.FilterAtom>(
  handleFormToFiltersModel(initialquery)
);
export const isMesaAtom = atomWithReset(initialquery.ismesa);

export const cleanFilters = () => {
  const resetsearchText = useResetAtom(searchTextAtom);
  const resetfilterBy = useResetAtom(filterByAtom);
  const resetfilters = useResetAtom(filtersAtom);
  const resetFiltersIsMesa = useResetAtom(isMesaAtom);

  return {
    resetsearchText,
    resetfilterBy,
    resetfilters,
    resetFiltersIsMesa,
  };
};
