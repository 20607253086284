import { Icon } from '@chakra-ui/react';

import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineInfoCircle,
} from 'react-icons/ai';

import { ReactComponent as YellowAttentionIcon } from '../../../../assets/icons/attention.svg';
import { ReactComponent as RedAttentionIcon } from '../../../../assets/icons/redAlert.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as ReloadClockIcon } from '../../../../assets/icons/reloadClock.svg';
import { Status } from '../../../../interfaces/types';
import { statusCSS } from '../../../../utils/css';
import * as I from './interfaces';

export const statusOptions = [
  {
    label: 'Limpar seleção',
    options: [{ label: 'Nenhum', value: '' }],
  },
  {
    label: 'status',
    options: [
      { label: 'Pendente', value: 'pending' },
      { label: 'Finalizado', value: 'finished' },
      { label: 'Não analisado', value: 'not-analyzed' },
    ],
  },

  {
    label: 'recomendações',
    options: [
      { label: 'Aprovado', value: 'approve' },
      { label: 'Rejeitado', value: 'reject' },
    ],
  },
];

export const filterByMesaOptions = [
  { label: 'Nenhum', value: '' },
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
];

export const filterByOptions = [
  { label: 'Nenhum', value: '' },
  { label: 'ID do pedido', value: 'id' },
  { label: 'ID do cliente', value: 'client' },
  { label: 'ID do fluxo', value: 'flow' },
  { label: 'Reason', value: 'reason' },
  { label: 'Documento', value: 'order.buyer.document' },
  { label: 'Telefone', value: 'order.buyer.phone' },
  { label: 'Email', value: 'order.buyer.email' },
  { label: 'Nome do portador', value: 'order.payments.holder-name' },
  { label: 'Fingerprint', value: 'order.payments.fingerprint' },
  { label: 'Referencia do pedido', value: 'order.order-reference' },
  { label: 'Nome do seller', value: 'order.products.seller.name' },
  { label: 'ID do seller', value: 'order.products.seller.id' },
  { label: 'Documento do seller', value: 'order.products.seller.document' },
];

export const today = new Date();
export const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(today.getDate() - 30);

export const handleStatus = (
  state: Status,
  recomendation: Status,
  status: Status,
  suspension: Status
): React.CSSProperties => {
  if (statusCSS[state] && state === 'withheld') {
    return statusCSS[state]();
  }

  if (statusCSS[suspension] && suspension === 'suspended') {
    return statusCSS[suspension]();
  }

  if (statusCSS[recomendation] && status !== 'not-analyzed') {
    return statusCSS[recomendation]();
  }

  if (statusCSS[state] && state !== 'timed-out') {
    return statusCSS[state]();
  }

  if (statusCSS[status]) {
    return statusCSS[status]();
  }
  return {};
};

export const handleShowStatus = (
  state: string,
  stateDisplay: string,
  recomendation: string,
  recomendationDisplay: string,
  status: string,
  statusDisplay: string,
  suspension?: string
): string => {
  if (state === 'withheld') {
    return stateDisplay;
  }

  if (!!recomendation && status !== 'not-analyzed') {
    return recomendationDisplay;
  }

  if (!!state && state !== 'timed-out' && state !== 'ready') {
    return stateDisplay;
  }

  if (suspension) {
    return suspension;
  }

  return statusDisplay;
};

export const handleShowStatusIcon = (
  state: string,
  recomendation: string,
  status: string,
  suspension?: string
): React.ReactElement => {
  if (state === 'in-review') {
    return <Icon src={ReloadClockIcon} display="block" />;
  }

  if (status === 'finished' && recomendation === 'approve') {
    return <Icon as={AiOutlineCheckCircle} display="block" />;
  }

  if (status === 'finished' && recomendation === 'reject') {
    return <Icon as={AiOutlineCloseCircle} display="block" />;
  }

  if (suspension || status === 'pending') {
    return <Icon src={ClockIcon} display="block" />;
  }

  return <Icon as={AiOutlineInfoCircle} display="block" />;
};

export const handleScore = (score: number): React.CSSProperties => {
  if (score < 0.33) {
    return { color: '#5b8c5a' };
  }
  if (score < 0.66) {
    return { color: '#f2a359' };
  }
  return { color: '#de2222' };
};

export const handleSLA = (sla: number): React.CSSProperties => {
  if (sla < 0) {
    return { color: '#de2222', fontWeight: 'bold' };
  }
  if (sla < 7200) {
    return { color: '#f2a359', fontWeight: 'bold' };
  }
  return {};
};

export const handleSLAIcon = (sla: number): React.ReactElement | null => {
  if (sla < 0) {
    return <RedAttentionIcon />;
  }

  if (sla < 7200) {
    return <YellowAttentionIcon />;
  }

  return null;
};

export const handleShowSLA = (recomendation: string): boolean => {
  if (recomendation) {
    return false;
  }
  return true;
};

export const formatDateForPicker = (isoDate?: string) => {
  if (isoDate) {
    const date = new Date(isoDate);
    const zonedTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);

    return zonedTime;
  }

  return '';
};

const handleStatusRecommendation = (value = '') => {
  const statuses = {
    pending: 'status',
    finished: 'status',
    'not-analyzed': 'status',
    approve: 'recommendation',
    reject: 'recommendation',
  } as I.StatusesType;

  const key = statuses[value] || 'status';
  return { key, value };
};

export const handleFormToFiltersModel = (values: I.FilterFormValues) => {
  const { key, value } = handleStatusRecommendation(values.status);

  const fromDateObj = values.from
    ? new Date(values.from)
    : new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  const toDateObj = values.to ? new Date(values.to) : new Date();

  return {
    [key]: value,
    'order.date.from': fromDateObj.toISOString(),
    'order.date.to': toDateObj.toISOString(),
    [values.filterBy || 'search']: values.text || '',
    isMesa: values.isMesa || '',
  };
};

export const handleStatusDefaultValue = (value: string) => {
  const matchingItem = statusOptions.find((item) =>
    item.options.some((option) => option.value === value)
  );

  const matchingOption = matchingItem
    ? matchingItem.options.find((option) => option.value === value)
    : undefined;

  return matchingOption;
};

export const handleFiltersDefaultValue = (
  value: string,
  filterList: I.SelectOptionType[]
) => {
  return filterList.find((item) => item.value === value);
};

export const formatDateWithTime = (date: Date): string => {
  const localISOString = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();

  return localISOString.replace(
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}).*$/,
    '$1-$2-$3T$4:$5'
  );
};

export const initialFilterState = {
  from: formatDateWithTime(thirtyDaysAgo),
  to: formatDateWithTime(today),
  status: '',
  filterBy: '',
  text: '',
};

export const isLeapYear = (year: number): boolean => {
  return new Date(year, 1, 29).getMonth() === 1;
};

const getYearMilliseconds = (fromDate: number, toDate: number): number => {
  const fromYear = new Date(fromDate).getFullYear();
  const toYear = new Date(toDate).getFullYear();
  const hasLeapYear = isLeapYear(fromYear) || isLeapYear(toYear);
  return hasLeapYear ? 31622400000 : 31536000000;
};

export const validateDateRange = (from: string, to: string): boolean => {
  const fromDate = Date.parse(from.split('T')[0]);
  const toDate = Date.parse(to.split('T')[0]);

  return toDate - fromDate <= getYearMilliseconds(fromDate, toDate);
};
