/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import queryString from 'query-string';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import { useAtom } from 'jotai';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormLabel, Icon, Input, InputGroup, Tooltip } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { RiArrowRightSLine } from 'react-icons/ri';
import { CiSearch } from 'react-icons/ci';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import InputSelect from '../inputs/select';
import InputText from '../inputs/text';
import LoadingSpinner from '../../../loadingSpinner';
import Overlay from '../overlay';
import Button from '../button';
import {
  filterByAtom,
  isMesaAtom,
  filtersAtom,
  searchTextAtom,
} from './stores';
import {
  filterByOptions,
  handleFormToFiltersModel,
  statusOptions,
  handleStatusDefaultValue,
  initialFilterState,
  formatDateForPicker,
  validateDateRange,
  today,
  thirtyDaysAgo,
  filterByMesaOptions,
  handleFiltersDefaultValue,
} from './utils';
import { removeEmptyValues } from '../../../../utils/removeEmptyValues';

import * as I from './interfaces';
import * as S from './styles';
import { customStyles } from '../inputs/utils';

export const CustomToolbarComponent = ({
  label,
  toolbarProps,
  searchMinWidth,
  hasQuery,
  width = '50%',
  padding = 'medium',
  marginBottom = 'medium',
  filters: filtersProps,
}: I.CustomTollbarComponentProps) => {
  const [searchText, setSearchText] = useAtom(searchTextAtom);
  const [filterBy, setFilterBy] = useAtom(filterByAtom);
  const [filters, setFilters] = useAtom(filtersAtom);
  const [isMesa, setIsMesa] = useAtom(isMesaAtom);

  const {
    replace,
    location: { pathname },
  } = useHistory();

  const defaultValues = {
    from: formatDateForPicker(filters?.['order.date.from']) || '',
    to: formatDateForPicker(filters?.['order.date.to']) || '',
    status: filters?.recommendation || filters?.status || '',
    filterBy: filterBy || '',
    isMesa: filters?.isMesa || isMesa || '',
    text: searchText || '',
  };

  const methods = useForm({
    defaultValues,
  });

  const { register, setValue, handleSubmit, reset } = methods;
  const selectStatusRef: any = useRef();
  const selectFilterRef: any = useRef();
  const selectMesaRef: any = useRef();

  const handleFilterChange = (newValue: unknown) => {
    const selectedOption = newValue as I.SelectOptionType;
    setValue('filterBy', selectedOption.value);
  };

  const handleStatusChange = (newValue: unknown) => {
    const selectedOption = newValue as I.SelectOptionType;
    setValue('status', selectedOption.value);
  };

  const handleFilterMesaChange = (newValue: unknown) => {
    const selectedOption = newValue as I.SelectOptionType;
    setValue('isMesa', selectedOption.value);
  };

  const handleClearInputs = () => {
    reset();
    replace(pathname);

    if (filtersProps?.orderStatus)
      selectStatusRef.current.setValue({ label: 'Nenhum', value: '' });
  };

  const handleCleanFilters = () => {
    handleClearInputs();

    if (JSON.stringify(defaultValues) !== JSON.stringify(initialFilterState)) {
      setFilters({
        'order.date.from': thirtyDaysAgo.toISOString(),
        'order.date.to': today.toISOString(),
      });
      setFilterBy('');
      setSearchText('');
      setIsMesa('');
      toolbarProps.onSearchChanged('');
    }
  };

  const startSearch = (values: I.FilterFormValues) => {
    if (values.from && values.to) {
      const isValidDateRange = validateDateRange(values.from, values.to);
      if (!isValidDateRange) {
        toast.error('A janela de busca não pode ser maior que 1 ano.');
        return;
      }

      if (values.to < values.from) {
        toast.error('Data final não pode ser menor que a data inicial.');
        return;
      }
    }

    if (hasQuery) {
      const queryParams = queryString.stringify(removeEmptyValues(values));
      replace(`${pathname}?${queryParams}`);
    }

    setFilters(handleFormToFiltersModel(values));
    setFilterBy(values?.filterBy || '');
    setSearchText(values?.text || '');
    setIsMesa(values?.isMesa || '');

    if (values.filterBy) {
      toolbarProps.onSearchChanged('');
    } else {
      toolbarProps.onSearchChanged(values?.text || '');
    }
  };

  return (
    <S.FilterContainer padding={padding} marginBottom={marginBottom}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(startSearch)}>
          <S.ModWrapper>
            {filtersProps?.dateFromTo && (
              <S.WrapItem>
                <S.Stack>
                  <S.StyledFormControl>
                    <InputGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Input
                          {...register('from')}
                          label="from"
                          type="datetime-local"
                          minH="2.75rem"
                          borderColor="neutral.500"
                        />
                      </MuiPickersUtilsProvider>
                      <FormLabel htmlFor="from">Data inicial</FormLabel>
                    </InputGroup>
                  </S.StyledFormControl>

                  <S.StyledFormControl>
                    <InputGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Input
                          {...register('to')}
                          label="to"
                          type="datetime-local"
                          minH="2.75rem"
                          borderColor="neutral.500"
                        />
                      </MuiPickersUtilsProvider>
                      <FormLabel htmlFor="to">Data final</FormLabel>
                    </InputGroup>
                  </S.StyledFormControl>
                </S.Stack>
              </S.WrapItem>
            )}

            {filtersProps?.orderStatus && (
              <S.WrapItem>
                <S.StyledFormControl variant="none">
                  <S.ModInputGroup>
                    <FormLabel variant="floating" className="active">
                      Status
                    </FormLabel>

                    <Select
                      {...register('status')}
                      ref={selectStatusRef}
                      placeholder=""
                      styles={customStyles}
                      options={statusOptions}
                      hideSelectedOptions={false}
                      onChange={handleStatusChange}
                      defaultValue={handleStatusDefaultValue(
                        defaultValues.status
                      )}
                    />
                  </S.ModInputGroup>
                </S.StyledFormControl>
              </S.WrapItem>
            )}

            {filtersProps?.isMesa && (
              <S.WrapItem>
                <S.StyledFormControl variant="none">
                  <S.ModInputGroup>
                    <FormLabel variant="floating" className="active">
                      Mesa
                    </FormLabel>

                    <InputSelect
                      formcontrol={{}}
                      label="Mesa"
                      {...register('isMesa')}
                      ref={selectMesaRef}
                      placeholder=""
                      options={filterByMesaOptions.map((option) => ({
                        option: option.label,
                        label: option.label,
                        value: option.value,
                      }))}
                      onChange={handleFilterMesaChange}
                      defaultValue={
                        handleFiltersDefaultValue(
                          defaultValues.isMesa,
                          filterByMesaOptions
                        )?.value
                      }
                    />
                  </S.ModInputGroup>
                </S.StyledFormControl>
              </S.WrapItem>
            )}

            {filtersProps?.filteredBy && (
              <S.WrapItem>
                <S.StyledFormControl variant="none">
                  <S.ModInputGroup>
                    <FormLabel variant="floating" className="active">
                      Filtrar por
                    </FormLabel>

                    <InputSelect
                      formcontrol={{}}
                      label="Filtrar por"
                      {...register('filterBy')}
                      ref={selectFilterRef}
                      placeholder=""
                      options={filterByOptions.map((option) => ({
                        option: option.label,
                        label: option.label,
                        value: option.value,
                      }))}
                      onChange={handleFilterChange}
                      defaultValue={
                        handleFiltersDefaultValue(
                          defaultValues.filterBy,
                          filterByOptions
                        )?.value
                      }
                    />
                  </S.ModInputGroup>
                </S.StyledFormControl>
              </S.WrapItem>
            )}

            <S.WrapButton minWidth={searchMinWidth ?? '300px'}>
              <InputText
                {...register('text')}
                label={label}
                formcontrol={{ style: { width } }}
              />

              <Tooltip label="Limpar Filtros" shouldWrapChildren>
                <Button
                  onClick={handleCleanFilters}
                  width="bigger-plus"
                  borderRadius="larger"
                >
                  <Icon
                    ml={1}
                    as={MdOutlinePlaylistRemove}
                    height="larger"
                    width="larger"
                  />
                </Button>
              </Tooltip>
              <Tooltip label="Pesquisar" shouldWrapChildren>
                <Button type="submit" width="bigger-plus" borderRadius="larger">
                  <Icon as={CiSearch} height="larger" width="larger" />
                </Button>
              </Tooltip>
            </S.WrapButton>
          </S.ModWrapper>
        </form>
      </FormProvider>
    </S.FilterContainer>
  );
};

export const EnhancedToolbar =
  ({
    propsComponents,
    label,
    filters,
    searchMinWidth,
    hasQuery,
  }: I.EnhancedToolbarProps) =>
  (toolbarProps: I.ToolbarProps) => {
    const Toolbar = propsComponents?.Toolbar;

    return (
      <div>
        {Toolbar && <Toolbar {...toolbarProps} />}
        <CustomToolbarComponent
          label={label}
          width="100%"
          toolbarProps={toolbarProps}
          filters={filters}
          searchMinWidth={searchMinWidth}
          hasQuery={hasQuery}
        />
      </div>
    );
  };

export const CustomLoadingComponent = () => {
  return (
    <Overlay>
      <LoadingSpinner />
    </Overlay>
  );
};

export const CustomIcon = () => {
  return <Icon as={RiArrowRightSLine} />;
};
