import React, { useEffect } from 'react';

import {
  IdColumn,
  documentColumn,
  nameAndIDColumn,
  statusColumn,
} from '../../components/base/v2/table/columns';
import usePartners from '../../hooks/api/partners';
import GenericListPage from '../genericList';
import { PartnersModel } from '../../interfaces/partners';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const PartnersList: React.FC = () => {
  useDocumentTitle('Trex - Parceiros');
  const { getPartnersList } = usePartners();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<PartnersModel>
      actionButton={{
        pushPath: '/partners/create',
        text: ' Criar novo parceiro',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Parceiros' }],
      }}
      title="Parceiros"
      table={{
        fetch: {
          apiCall: getPartnersList,
          title: 'parceiros',
        },
        body: {
          columns: [
            nameAndIDColumn('/partners/info'),
            IdColumn,
            documentColumn,
            statusColumn,
          ],
          searchLabel: 'Busque por nome, documento ou ID de um parceiro',
          accessURL: '/partners/info',
          isSearch: false,
          hasQuery: true,
        },
      }}
    />
  );
};

export default PartnersList;
