import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../components/loadingSpinner';
import usePartners from '../../../hooks/api/partners';
import PartnerUpsertForm from '../../../components/forms/partners';

import * as S from '../styles';
import * as I from '../../../interfaces/partners';

const PartnersInfo: React.FC = () => {
  const [partner, setPartner] = useState<I.PartnersModel>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useHistory();
  const { getOnePartner } = usePartners();

  const { id } = useParams<{ id: string }>();

  const handleEditing = () => {
    setIsDisabled(!isDisabled);
  };
  const handleDirectToList = () => {
    push(`/partners/info/list/${partner?.id}`);
  };
  const loadInfo = async () => {
    setIsLoading(true);
    const { data } = await getOnePartner({ id });
    setPartner(data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadInfo();
  }, []);

  if (isLoading || !partner) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Parceiros', href: '/partners' },
        { label: partner.name },
      ]}
      title={partner.name}
      showBackButton
      backButtonUrl="/partners"
      handleAction={handleEditing}
      actionText="Editar Parceiro"
      isDisabled={isDisabled}
      secondaryActions={[
        {
          text: 'Clientes',
          action: handleDirectToList,
        },
      ]}
    >
      <PartnerUpsertForm
        partner={partner}
        loadInfo={loadInfo}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
    </GenericForm>
  );
};

export default PartnersInfo;
